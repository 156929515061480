


@media screen and (max-width: 400px) {
  #features {
    padding: 20px;
    width: 100%;
  }
  #about,
  #services,
  #testimonials,
  #team,
  #getquote,
  #footer {
    width: 100%;
  }

  #portfolio {
    width: 100%;
  }
  .footer-container {
    display: flex;
    justify-content: space-around; /* Adjust as needed */
  }
  
  .footer-section {
    flex: 1;
    margin: 0 10px; /* Adjust margin as needed */
  }
}
