/* Common styles for the website */
/* body {
    
  } */
  
  /* Other common styles... */
  
  /* Styles specific to the FAQ component */
  .faq-wrapper {
    width: 100%;
    max-width: 800px; /* Adjust as needed */
    margin: 0 auto;
  }
  
  .faq-title {
    font-size: 24px;
    margin-bottom: 20px;
    
  }
/*   
  .faq-container {
    width: 100%;
  }
   */
  .faq-item {
    margin-bottom: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .faq-question {
    padding: 20px;
    cursor: pointer;
    font-weight: bold;
    color: #181818;
    font-size: 20px;
  }
  
  .faq-question.active {
    background-color: #eaeaea;
  }
  
  .faq-answercont {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
  }
  
  .faq-answer {
    padding: 20px;
    line-height: 1.5;
    
    color: #2e2e2e;
    font-size: 18px;
  }
  
  .faq-question::after {
    content: "+";
    float: right;
  }
  
  .faq-question.active::after {
    content: "-";
  }
  