
@import url("https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap");
#footer {
 
  padding-top: 20px;
}

/* .footer-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-start;
} */




/* .footer-socials {
  text-align: center;
} */


.footer {
  position: relative;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 0 !important;
  width: 100%;
  text-align: center;
  background-color: #000000;
  color: #fff;
  margin-top: 10px; /* Add margin to create space between iframe and copyright */
}
/* Centered copyright */
.copyright {
  text-align: center;
  padding: 20px 0;
  background-color: #050505;
  color: #fff;
  margin-top: 30px; /* Add margin to create space between iframe and copyright */
}

/* 
******************************************************************
button hover class */

.wrapper .button {

  display: inline-block;
  height: 60px;
  width: 60px;
  margin: 0 5px;
  overflow: hidden;
  background: #164ab1;
  color: #ffffff;
  border-radius: 15px;
  cursor: pointer;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-out;
}
.wrapper .button:hover {
  width: 150px;
}
.wrapper .button .icon {
  display: inline-block;
  height: 60px;
  float: left;
  width: 60px;
  text-align: center;
  border-radius: 50px;
  box-sizing: border-box;
  line-height: 60px;
  transition: all 0.3s ease-out;
}
.wrapper .button:nth-child(1):hover .icon {
  background: #66000000;
}


.wrapper .button .icon i {
  font-size: 45px;
  line-height: 60px;
  transition: all 0.3s ease-out;
}
.wrapper .button:hover .icon i {
  color: #ffffff;
}
.wrapper .button span {
  font-size: 20px;
  font-weight: 500;
  line-height: 60px;
  margin-left: 10px;
  transition: all 0.3s ease-out;
}
.wrapper .button:nth-child(1) span {
  color: #ffffff;
}

/* *********************************************************************************** */



.wrapperi .buttoni {
  display: inline-block;
  height: 60px;
  width: 60px;
  margin: 0 5px;
  overflow: hidden;
  background: linear-gradient(45deg, #f7cc0a, #f5489e, #7d4ee9);
  color: #ffffff;
  border-radius: 15px;
  cursor: pointer;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-out;
}
.wrapperi .buttoni:hover {
  width: 150px;
}
.wrapperi .buttoni .iconi {
  display: inline-block;
  height: 60px;
  float: left;
  width: 60px;
  text-align: center;
  border-radius: 50px;
  box-sizing: border-box;
  line-height: 60px;
  transition: all 0.3s ease-out;
}
.wrapperi .buttoni:nth-child(1):hover .iconi {
  background: #66000000;
}


.wrapperi .buttoni .iconi i {
  font-size: 45px;
  line-height: 0px;
  transition: all 0.3s ease-out;
}
.wrapperi .buttoni:hover .iconi i {
  color: #ffffff;
}
.wrapperi .buttoni span {
  font-size: 20px;
  font-weight: 500;
  line-height: 60px;
  margin-left: -5px;
  transition: all 0.3s ease-out;
}
.wrapperi .buttoni:nth-child(1) span {
  color: #ffffff;
}

/* ***********************************************************************
*-******************************************************************** */



.wrapperyt .buttonyt {
  display: inline-block;
  height: 60px;
  width: 60px;
  margin: 0 5px;
  overflow: hidden;
  background: red;
  color: #ffffff;
  border-radius: 15px;
  cursor: pointer;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-out;
}
.wrapperyt .buttonyt:hover {
  width: 150px;
}
.wrapperyt .buttonyt .iconyt {
  display: inline-block;
  height: 60px;
  float: left;
  width: 60px;
  text-align: center;
  border-radius: 50px;
  box-sizing: border-box;
  line-height: 60px;
  transition: all 0.3s ease-out;
}
.wrapperyt .buttonyt:nth-child(1):hover .iconyt {
  background: #66000000;
}


.wrapperyt .buttonyt .iconyt i {
  font-size: 45px;
  line-height: 0px;
  transition: all 0.3s ease-out;
}
.wrapperyt .buttonyt:hover .iconyt i {
  color: #ffffff;
}
.wrapperyt .buttonyt span {
  font-size: 20px;
  font-weight: 500;
  line-height: 60px;
  margin-left: -5px;
  transition: all 0.3s ease-out;
}
.wrapperyt .buttonyt:nth-child(1) span {
  color: #ffffff;
}
