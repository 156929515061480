.gallery-items {
  width: 100%;
  padding: 5px;
  display: flex;
  justify-content: center;
}

.container{
  padding: 0;
}

.photos-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 10px;
  justify-content: center;
  /* display: flex;
  flex-direction: column;
  width: 200px; */
}


/* @media (max-width: 768px) {
  .gallery-items {
    width: 100px !important;
    height: 100px !important;
  
    padding: 5px;
    display: flex;
    justify-content: center;
  }

  .photos-container {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }
} */

.photo-lib-container {
  width: 99%;
}

@media (min-width: 401px) {
  .photo-lib-container {
    margin-top: 15vh;
  }

  .light-gallery {
    display: flex;
    justify-content: center;
    transform: translateX(47%);
    width: 55%;
  }
}

@media (max-width: 400px) {
  .photo-gallery-title {
    margin-top: 15vh;
  }

  .light-gallery {
    display: flex;
    justify-content: center;
    transform: translateX(30%);
    width: 65%;
  }


}

.gallery-items:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0);
  /* Add shadow effect on hover */
}